
import Button from "@/components/base/Button"
import OImage from "@/components/base/image/OImage"
import CheckboxField from "@/components/search/v2/header/filters/CheckboxField"
import { mdiBrushOutline } from "@mdi/js"

export default {
    name: "SectionTemplateSelect",
    components: {
        Button,
        OImage,
        CheckboxField,
    },
    model: {
        prop: "item-id",
        event: "change:item-id",
    },
    props: {
        itemId: {
            type: Number,
            default: null,
        },
    },
    icons: {
        mdiBrushOutline,
    },
}
